vue
<template>
    <div>
        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <p class="title-bold">Varices y arañas vasculares</p>
            </div>
        </div>

        <div class="p-grid">
            <div class="p-col-12 p-d-flex p-jc-center">
                <img class="img-fluid" alt="araña vascular vena varicosa" src="../../assets/venas-varicosas-aranas.jpg" />
            </div>

            <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
                <Card styleClass="p-card-shadow">
                    <template #content> 
                        <p>
                            Las varices son un problema muy frecuente que produce una degeneración en la pared y las
                            válvulas de las venas, impidiendo que el retorno venoso se realice adecuadamente. En Centro
                            Médico Integral somos especialistas en el tratamiento integral de varices. En nuestra Clínica,
                            lo primero es realizar una breve historia clínica con los antecedentes, horas que permanece de
                            pie o sentada la paciente y estilo de vida que nos permita diagnosticar correctamente el origen
                            de sus varices. El tratamiento de las varices con <span class="p-text-bold">Etoxisclerol</span>,
                            es el más eficaz pues es el único método con el que cualquier variz, de cualquier tamaño y
                            localización, se elimina satisfactoriamente.
                        </p>
                        <p class="p-text-bold">¿En qué consiste el tratamiento?</p>
                        <p>
                            Se trata de una técnica indolora que no precisa de anestesia, que no exige preparación previa de
                            ningún tipo, ni ayuno. Es incluso compatible con tratamientos a base de antiagregantes o
                            anticoagulantes. Es ideal para pacientes de todas las edades, personas que puedan sufrir de
                            patología cardiaca o cerebral, pues no hacemos uso de una anestesia. Tras el tratamiento se pone
                            un vendaje en las piernas de la paciente y tendrá que andar durante 30 min después del mismo y
                            utilizar unas medias compresivas. No requiere guardar reposo ni limita la incorporación del
                            paciente a su vida diaria. Nuestra técnica resulta extremadamente óptima para quienes prefieran
                            evitar la cirugía y todos los inconvenientes asociados a la misma (anestesia, postoperatorio,
                            cicatrices, etcétera). Para que sus efectos sean prolongados, precisa de un número de sesiones
                            variable, dependiendo de cada caso particular.
                        </p>
                    </template>

                    <template #footer>
                        <span>Para más información no dude en ponerse en contacto con nuestro
                            Centro médico al 637 857 384.</span>
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
import Card from 'primevue/card';
export default {
    name: 'VaricesVue',

    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    components: {
        Card
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>.title-bold {
    font-size: 30px;
    font-weight: bold
}

.img-fluid {
    width: 100%;
    height: auto;
}

@media (min-width: 576px) {
    .img-fluid {
        max-width: 50%;
        height: auto;
    }
}</style>